import React from "react"
import Ticker from "./ticker.component"

const Counter = ({ bgImage }) => {
  return (
    <div
      className="fact-counter-wrapper padding ptb-xs-40"
      style={{ background: `url(${bgImage})` }}
    >
      <div className="container text-center">
        <div className="row">
          <div className="col-lg-3 col-md-6">
            <div className="single-fact">
              <div className="icon-boxed">
                <i
                  className="fa fa-smile-o"
                  aria-hidden="true"
                  style={{ lineHeight: "70px" }}
                />
              </div>
              <div className="counter_count">
                <Ticker className="counter" end={2470} />
                <p>Happy Clients</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mt-xs-30">
            <div className="single-fact">
              <div className="icon-boxed">
                <i
                  className="fa fa-th-list"
                  aria-hidden="true"
                  style={{ lineHeight: "70px" }}
                />
              </div>
              <div className="counter_count">
                <Ticker className="counter" end={3120} />
                <p>Total Projects</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mt-sm-30 mt-xs-30">
            <div className="single-fact">
              <div className="icon-boxed">
                <i
                  className="fa fa-map-marker"
                  aria-hidden="true"
                  style={{ lineHeight: "70px" }}
                />
              </div>
              <div className="counter_count">
                <Ticker className="counter" end={7} />
                <p>Service Location</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mt-sm-30 mt-xs-30">
            <div className="single-fact">
              <div className="icon-boxed">
                <i
                  className="fa fa-trophy"
                  aria-hidden="true"
                  style={{ lineHeight: "70px" }}
                />
              </div>
              <div className="counter_count">
                <Ticker className="counter" end={12} />
                <p>Years in business</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Counter
