import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

const ServiceWidget = ({ data }) => {
  return (
    <div id="services-widgets" className="row">
      {data.allContentfulServiceCategory.edges.map(service => {
        // Normalize the slug to have single /services/ prefix
        const formattedSlug = service.node.slug.startsWith("/")
          ? service.node.slug
          : `/${service.node.slug}`

        // Remove any double /services/ if present
        const finalSlug = formattedSlug.replace(
          /\/services\/services\//,
          "/services/"
        )

        return (
          <div
            key={service.node.id}
            className="services-block col-lg-3 col-md-4 col-sm-6 col-6 mb-30"
          >
            <div className="inner-box hvr-float">
              <Link to={finalSlug}>
                <div className="image">
                  {service.node.image && (
                    <Img
                      fluid={service.node.image.fluid}
                      alt={service.node.name}
                    />
                  )}
                </div>
                <div className="lower-box">
                  <h3>{service.node.name}</h3>
                </div>
              </Link>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default ServiceWidget
